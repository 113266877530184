import React from 'react'

import { CurrencyCode, Order, OrderLine } from '~common/generated/admin-graphql'
import { Price } from '~common/components/product'
import { RemoveCouponForm } from '~common/components/coupon/RemoveCouponForm'
import { DiscountMap } from './DiscountMap'

type Interval = 'month' | 'year' | 'week' | 'future'
type RecurringTotals = {
  [key in Interval]: { [key: number]: number }
}

export const OrderTotals: React.FC<{
  order: Order
  pricesIncludeTax?: boolean
  removeCouponPath?: string
  disableCoupons?: boolean
}> = ({ order, pricesIncludeTax, disableCoupons = true, removeCouponPath }) => {
  const currencyCode = order?.currencyCode || CurrencyCode.Usd
  const convenienceFee =
    order?.surcharges?.reduce((acc, m) => acc + m.price, 0) || 0
  /**
   * Recurring groupings:
   * Structure
   *  {
   *     month: {
   *        `${x}`: total,
   *        `${x}`: total,
   *     }
   *     year: {
   *        `${x}`: total,
   *        `${x}`: total,
   *     }
   *     week: {
   *        `${x}`: total,
   *        `${x}`: total,
   *     }
   *  }
   * */

  const recurringTotals = React.useMemo(
    () =>
      order.lines.reduce(
        (acc, line: OrderLine) => {
          const { subscriptionDetails } = line
          if (!subscriptionDetails) {
            return acc
          }

          const {
            amount,
            discountedDownpayment,
            downpaymentIsRegistration,
            autoRenew,
            downpaymentWithSurcharge,
            billingInterval,
            billingIntervalCount,
            durationInterval,
            durationIntervalCount,
          } = subscriptionDetails ?? {}
          let intervalStr = billingInterval.toString() as Interval
          let intervalCount = billingIntervalCount
          let renewalStr = durationInterval.toString() as Interval
          let renewalIntervalCount = durationIntervalCount
          if (!acc[intervalStr]) {
            acc[intervalStr] = {}
          }
          if (!acc[renewalStr]) {
            acc[renewalStr] = {}
          }

          // if (!autoRenew) {
          intervalStr = 'future'
          intervalCount = 1
          // }
          // Recurring downpayments
          if (
            autoRenew &&
            !downpaymentIsRegistration &&
            discountedDownpayment
          ) {
            // we have repeating renewal
            if (typeof acc[renewalStr][renewalIntervalCount] === 'undefined') {
              acc[renewalStr][renewalIntervalCount] = 0
            }
            const myVal = acc[renewalStr][renewalIntervalCount]
            acc[renewalStr][renewalIntervalCount] =
              myVal + discountedDownpayment
          }

          // Recurring subscriptions
          if (typeof acc[intervalStr][intervalCount] === 'undefined') {
            acc[intervalStr][intervalCount] = 0
          }
          const currentVal = acc[intervalStr][intervalCount]

          // acc[intervalStr][intervalCount] = currentVal + amount
          // We are aggregating everything into future
          acc[intervalStr][intervalCount] =
            currentVal + amount + discountedDownpayment

          return acc
        },
        {
          future: {},
          month: {},
          year: {},
          week: {},
        } as RecurringTotals,
      ),
    [order],
  )

  const recurringCount = React.useMemo(
    () =>
      Object.keys(recurringTotals).filter(
        (interval) => Object.keys(recurringTotals[interval as Interval]).length,
      ).length,
    [recurringTotals],
  )

  return (
    <>
      {!disableCoupons ? (
        <DiscountMap
          order={order}
          renderRemove={(couponCode: string) => {
            return order && removeCouponPath ? (
              <RemoveCouponForm
                key={couponCode}
                couponCode={couponCode}
                currentCouponCodes={order.couponCodes}
                removePath={removeCouponPath}
                // loading={loading}
                className="mt-2"
              />
            ) : (
              <p />
            )
          }}
        />
      ) : null}
      {recurringCount === 0 ? (
        <div className="flex justify-between text-sm font-normal text-gray-700 dark:text-slate-400">
          <p className="font-medium">Recurring</p>
          <p>
            <Price
              priceWithTax={0}
              interval="month"
              intervalCount={1}
              currencyCode={currencyCode}
            />
          </p>
        </div>
      ) : null}
      {recurringCount > 0
        ? Object.keys(recurringTotals)
            .filter((interval) => interval === 'future')
            .map((interval, idx) =>
              Object.keys(recurringTotals[interval as Interval])

                .map((intervalC, idx2) => {
                  const intervalCount = parseInt(intervalC, 10)
                  const total =
                    recurringTotals[interval as Interval][intervalCount]
                  return (
                    <div
                      key={`price-${idx}-${idx2}`}
                      className="flex justify-between text-sm font-normal text-gray-700 dark:text-slate-300"
                    >
                      <p className="font-medium dark:text-slate-400">
                        {idx || idx2
                          ? ''
                          : interval === 'future'
                            ? 'Upcoming'
                            : 'Recurring'}
                      </p>
                      <p>
                        <Price
                          priceWithTax={total}
                          interval={interval}
                          intervalCount={intervalCount}
                          currencyCode={currencyCode}
                        />
                      </p>
                    </div>
                  )
                }),
            )
        : null}
      {convenienceFee ? (
        <div className="flex justify-between text-base font-medium text-gray-900 dark:text-slate-300 my-1">
          <p className="font-medium dark:text-slate-400">
            Convenience fees<sup> *</sup>
          </p>
          <p>
            {currencyCode && (
              <Price
                priceWithTax={convenienceFee}
                currencyCode={currencyCode}
              />
            )}
          </p>
        </div>
      ) : null}
      <div className="flex justify-between text-base font-medium text-gray-900 dark:text-slate-300 my-1">
        <p className="font-medium dark:text-slate-400">Pay now</p>
        <p>
          {currencyCode && (
            <Price
              priceWithTax={
                ((pricesIncludeTax
                  ? order?.subTotalWithTax
                  : order?.subTotal) ?? 0) + convenienceFee
              }
              currencyCode={currencyCode}
            />
          )}
        </p>
      </div>
      {recurringCount > 0 ? (
        <div className="w-full pt-4">
          {convenienceFee > 0 ? (
            <dl className="flex pb-2.5 text-gray-400 dark:text-slate-500">
              <dt className="">
                <sup>*</sup>&nbsp;
              </dt>
              <dd>
                <p className="text-xs w-full">
                  Convenience fees are applied at time of charge and are subject
                  to change.
                </p>
              </dd>
            </dl>
          ) : null}
          <dl className="flex pb-2.5 text-gray-400 dark:text-slate-500">
            <dt className=""></dt>
            <dd>
              <p className="text-xs w-full">
                Periodic memberships can require a downpayment and, if you are
                signing up mid cycle, may be pro-rated through the end of the
                current cycle.
              </p>
            </dd>
          </dl>
          <dl className="flex text-gray-400 dark:text-slate-500">
            <dt className=""></dt>
            <dd>
              <p className="text-xs w-full">Memberships automatically renew.</p>
            </dd>
          </dl>
        </div>
      ) : null}
      {/* <p className="mt-0.5 text-sm text-gray-500">
                        Shipping will be calculated at
                        checkout.
                      </p> */}
    </>
  )
}
